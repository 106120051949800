<template>
    <v-app>
        <v-card>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn class @click="fetchData" text icon color="blue">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-container fluid grid-list-md>
                    <v-layout wrap>
                        <v-flex xs12 md12>
                            <v-layout >
                                <v-flex>
                                    <v-card hover height="100%" class="text-center">
                                        <v-icon x-large>mdi-file</v-icon>
                                        <v-card-text class="title mb-2">
                                            Última Orden por Canal
                                        </v-card-text>
                                        <v-card-text v-if="channelSelectedObject">
                                            <router-link  :to="{name: 'orden', params: {orden: channelSelectedObject.order_id}}" class="text-decoration-none text-h6" target="_blank">
                                                {{channelSelectedObject.order_reference}}
                                            </router-link>
                                            <br>
                                            <div v-text="$moment(channelSelectedObject.created_at).locale('es').format('LLLL')"></div>
                                        </v-card-text>
                                        <v-card-text v-else>
                                            No hay datos
                                        </v-card-text>
                                        <v-card-text>
                                            <v-select placeholder="Elige un marketplace" v-model="channelSelected" :items="channelsList"></v-select>
                                        </v-card-text>
                                    </v-card>
                                </v-flex>
                                <v-flex>
                                    <v-card hover height="100%" class="text-center">
                                        <v-icon x-large>mdi-file-send</v-icon>
                                        <v-card-text class="title mb-2">
                                            Última Orden inyectada por WMS
                                        </v-card-text>
                                        <v-card-text v-if="wmsSelectedObject">
                                            <router-link  :to="{name: 'orden', params: {orden: wmsSelectedObject.order_id}}" class="text-decoration-none text-h6" target="_blank">
                                                {{wmsSelectedObject.order_reference}}
                                            </router-link>
                                            <br>
                                            <div v-text="$moment(wmsSelectedObject.created_at).locale('es').format('LLLL')"></div>
                                        </v-card-text>
                                        <v-card-text v-else>
                                            No hay datos
                                        </v-card-text>
                                        <v-card-text>
                                            <v-select placeholder="Elige un WMS" v-model="wmsSelected" :items="wmsList"></v-select>
                                        </v-card-text>
                                    </v-card>
                                </v-flex>
                                <v-flex>
                                    <v-card hover height="100%" class="text-center">
                                        <v-icon x-large>mdi-file-pdf</v-icon>
                                        <v-card-text class="title mb-2">
                                            Última Etiqueta generada
                                        </v-card-text>
                                        <v-card-text v-if="lastShippingLabelOrder">
                                            <a  :href="lastShippingLabelOrder.shipping_label_url" class="text-decoration-none text-h6" target="_blank">
                                                {{lastShippingLabelOrder.order_reference}}
                                            </a>
                                            <br>
                                            <div v-text="lastShippingLabelOrder.carrier_name"></div>
                                            <br>
                                            <div v-text="$moment(lastShippingLabelOrder.updated_at).locale('es').format('LLLL')"></div>
                                        </v-card-text>
                                        <v-card-text v-else>
                                            No hay datos
                                        </v-card-text>
                                    </v-card>
                                </v-flex>
                                <v-flex>
                                    <v-card hover height="100%" class="text-center">
                                        <v-icon x-large>mdi-file</v-icon>
                                        <v-card-text class="title mb-2">
                                            Última Orden por estado
                                        </v-card-text>
                                        <v-card-text v-if="orderStateSelectedObject">
                                            <router-link  :to="{name: 'orden', params: {orden: orderStateSelectedObject.order_id}}" class="text-decoration-none text-h6" target="_blank">
                                                {{orderStateSelectedObject.order_reference}}
                                            </router-link>
                                            <br>
                                            <div v-text="$moment(orderStateSelectedObject.updated_at).locale('es').format('LLLL')"></div>
                                        </v-card-text>
                                        <v-card-text v-else>
                                            No hay datos
                                        </v-card-text>
                                        <v-card-text>
                                            <v-select placeholder="Elige un estado" v-model="orderStateSelected" :items="orderStatesList"></v-select>
                                        </v-card-text>
                                    </v-card>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-text>
        </v-card>
    </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
    mounted(){
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Panel de control", route: "dashboard-beta" },
        ]);
        this.fetchData();
    },
    data(){
        return{
            lastOrdersByChannel: [],
            lastOrdersInjected: undefined,
            lastShippingLabelOrder: undefined,
            lastOrdersByOrderState: [],
            orderStatesList: [],
            channelsList: [],
            wmsList: [],
            orderStateSelected: undefined,
            channelSelected: undefined,
            wmsSelected: undefined,
            orderStateSelectedObject: undefined,
            channelSelectedObject:undefined,
            wmsSelectedObject: undefined
        }
    },
    methods:{
        fetchData(){
            var vm = this;
            this.axios({
                url: 'dashboard-beta/data',
                method: 'GET'
            }).then( response => {
                
                vm.lastOrdersByChannel = response.data.marketplace_orders_most_recent;
                vm.lastOrdersInjected = response.data.wms_injected_orders;
                vm.lastShippingLabelOrder = response.data.last_generated_shipping_label;
                vm.lastOrdersByOrderState = response.data.last_orders_by_order_state;
                vm.channelsList = [];
                vm.lastOrdersByChannel.forEach(e => {
                    vm.channelsList.push(e.marketplace_name);
                    if(!vm.channelSelected){
                        vm.channelSelected = e.marketplace_name;
                    }
                });
                vm.orderStatesList = [];
                vm.lastOrdersByOrderState.forEach(e => {
                    vm.orderStatesList.push(e.order_state_name)
                    if(e.order_state_name == 'On Hold'){
                        vm.orderStateSelected = e.order_state_name
                    }
                });
                vm.wmsList = [];
                vm.lastOrdersInjected.forEach( e => {
                    vm.wmsList.push(e.wms_name);
                    if(!vm.wmsSelected){
                        vm.wmsSelected = e.wms_name;
                    }
                });
            }).catch( error => {

            });
        }
    },
    watch:{
        orderStateSelected(newValue){
            if(newValue){
                let founded = this.lastOrdersByOrderState.find(e => e.order_state_name == newValue);
                this.orderStateSelectedObject = founded;
            }else{
                this.orderStateSelectedObject = undefined;
            }
        },
        channelSelected(newValue){
            if(newValue){
                let founded = this.lastOrdersByChannel.find(e => e.marketplace_name == newValue);
                this.channelSelectedObject = founded;
            }else{
                this.channelSelectedObject = undefined;
            }
        },
        wmsSelected(newValue){
            if(newValue){
                let founded = this.lastOrdersInjected.find(e => e.wms_name == newValue);
                this.wmsSelectedObject = founded;
            }else{
                this.wmsSelectedObject = undefined;
            }
        }
    }
}
</script>